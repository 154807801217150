import * as React from "react"
import Layout from '../components/layout'

const NotFoundPage = () => {
  return (
    <Layout pageTitle="404 Not Found">


      <div className="container py-10 py-md-10">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-8 text-center">

            <h1 className="display-2 fw-bold text-dark">
              😢 <br /> Sorry, Page Not Found
            </h1>

            <h3 className="lead text-dark-75 mb-0">
              Unfortunately, that page seems to have gone missing.
            </h3>

          </div>
        </div>
      </div>


    </Layout>
  )
}

export default NotFoundPage
